<template>
	<div>
		<!-- 面包屑 -->
		<Breadcrumb></Breadcrumb>
		<el-card class="mt-2">
		<div class="w-100 h-auto flex flex-column">
			<el-form ref="form" :model="form" :rules="rules" label-width="130px" size="small">
				<el-form-item label="荣誉名称" prop="title">
				   <el-input v-model="form.title" placeholder="请输入荣誉名称" type="text" clearable />
				</el-form-item>
				<el-form-item label="奖励级别" prop="type">
					<el-radio-group v-model="form.type">
						<el-radio :label="1" class="w-100 mb-2">国家级以上奖励(5分)</el-radio>
						<el-radio :label="2" class="w-100 mb-2">省级以上奖励(4分)</el-radio>
						<el-radio :label="3" class="w-100 mb-2">市级奖励（市或市政府盖章）(3分)</el-radio>
						<el-radio :label="4" class="w-100 mb-2">市教育局级奖励(含市人社局)(2分)</el-radio>
						<el-radio :label="5" class="w-100 mb-2">校级奖励(1分)</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="获奖时间" prop="time" style="width: 450px;">
				  <el-date-picker
					v-model="form.time"
					type="date"
					value-format="yyyy-MM-dd"
					class="w-100"
					placeholder="请选择获奖时间">
				  </el-date-picker>
				</el-form-item>
				<el-form-item label="上传材料" prop="files">
					<div class="flex flex-column flex-wrap">
						<div class="flex mr-1 mb-2">
							<el-upload
							  v-for="(item,index) in files" :key="index"
							  class="upload-border"
							  action="/manage/file/upload"
							  :show-file-list="false"
							  :on-success="uploadSuccess"
							  :before-upload="beforeUpload">
							  <div class="position-relative flex align-center flex-wrap w-100 overflow-hidden" style="height: 100px;" v-if="item.id">
								<img :src="item.path" style="width: 100px;height: 100px;" v-if="item.ext == 'jpg' || item.ext == 'jpeg' || item.ext == 'png'">
							  	<span style="width: 100px;word-wrap:break-word;word-break:break-all;overflow: hidden;" v-else>{{item.name || ''}}</span>
							  	<i class="el-icon-delete text-white position-absolute flex-all" style="width: 25px;height: 25px;top: 0;right: 0;background-color: rgba(0,0,0,0.4);cursor: pointer;" @click.stop="delFile(index)"></i>
							  </div>
							  <i v-else class="el-icon-plus uploader-icon"></i>
							</el-upload>
							<el-upload
							  v-if="files.length < 5"
							  class="upload-border"
							  action="/manage/file/upload"
							  :show-file-list="false"
							  :on-success="uploadSuccess"
							  :before-upload="beforeUpload">
							  <i class="el-icon-plus uploader-icon"></i>
							</el-upload>
						</div>
						<small class="d-block text-secondary" style="line-height: 20px;">上传照片及相关资料</small>
					</div>
				</el-form-item>
			</el-form>
			<div class="w-100 flex-all mt-2">
			  <el-button size="medium" @click="cancel">取 消</el-button>
			  <el-button type="primary" size="medium" @click="handleSubmit" style="margin-left: 30px;">确 定</el-button>
			</div>
		</div>
		</el-card>
	</div>
</template>

<script>
import common from "@/common/mixins/common.js"
import Breadcrumb from '@/components/Breadcrumb';
export default {
	inject:['app'],
	mixins:[common],
	components: {
		Breadcrumb,
	},
	data() {
		return {
			preUrl: 'user_hjmc',
			autoRequest: false,
			queryForm: {
				page: 1,
				limit: 10,
				keyword: '',
			},
			form:{
				s_id:'',
				title:'',
				type:'',
				files:null,
				time:'',
			},
			rules:{
				title: [
				  { required: true, message: '请填写荣誉名称', trigger: ['blur','change'] }
				],
				type: [
				  { required: true, message: '请选择类型', trigger: ['blur','change'] }
				],
				files: [
				  { required: true, message: '请上传文件', trigger: ['blur','change'] }
				],
				time: [
				  { required: true, message: '请选择获奖时间', trigger: ['blur','change'] }
				],
			},
			files:[],
		}
	},
	mounted() {
		if(this.$route.query.info){
			 this.form = this.$route.query.info
			 this.files = this.$route.query.info.files
		}
		this.getsemester()
	},
	methods:{
		reset() {
		  this.form = {
			 s_id:'',
			 title:'',
			 type:'',
			 files:null,
			 time:'',
		  }
		  this.resetForm('form')
		},
		uploadSuccess(e){
		   if(e.status){
			 this.files.push(e.data)
		   }
		},
		beforeUpload(){},
		delFile(index){
			this.files.splice(index,1)
		},
		handleSubmit() {
			this.form.files = this.files
		  this.$refs.form.validate(valid => {
		    if (valid) {
		      if (this.form.id === undefined) {
		        this.axios.post('/manage/'+this.preUrl + '/add', this.form).then(res => {
		          if (res.status) {
		            this.$message.success('申请成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      } else {
		        this.axios.put('/manage/'+this.preUrl + '/edit/' + this.form.id, this.form).then(res => {
		          if (res.status) {
		            this.$message.success('修改成功')
		            this.$router.go(-1);
		          } else {
		            this.$message.error(res.msg)
		          }
		        })
		      }
		    }
		  })
		},
		cancel(){
			this.$router.go(-1);
		},
	},
}
</script>

<style>
</style>